/*
 * HEADER
 */

.c-header-logo {
    width: 150px;

    @include media-query(md) {
        width: 250px;
    }
}

.c-header-button {
    font-weight: normal;
}
