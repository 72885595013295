/*
* FORMS
*/

.c-form-group {

}

.c-form-input-text,
.c-form-input-password,
.c-form-input-textarea {
    background-color: transparent;
    border-bottom: 1px solid darken($white, 15%);
}

.c-form-input-textarea {
    border: 1px solid darken($white, 15%);
}

// Errors

.c-form-error {

    p {
        color: $error;
    }
}

.c-form-description {

    p {
        font-size: .75em;
    }
}

.c-form-group.hasError {

    .c-label {
        color: $error;
    }

    .c-form-input {
        border: 1px solid $error;
    }
}
