/*
 * HERO
 */

.c-hero-home {

  display: block;
  width: 100%;
  overflow: hidden;

  .o-grid, .o-col {
    position: relative;
  }

  h2 {
    display: block;
    position: absolute;
    z-index: 99;
    top: 75%;
    width: 100%;
    font-family: $baseFont;
    font-weight: 100;
    font-size: 2rem;

    @include media-query(sm) {
      font-size: 3rem;
    }

    @include media-query(md) {
      top: 65%;
      font-size: 5rem;
    }
  }

  h2.right {
    color: $white;
  }

  .c-hero-image-container {
      position: relative;
      display: block;
      width: 100vw;
      right: 0;
      margin-right: 0;

      img {
          position: relative;
          width: 50%;
          height: 35vh;
          object-fit: cover;

          @include media-query(sm) {
            height: 50vh;
          }

          @include media-query(md) {
            height: 75vh;
          }
      }
  }
}