/*
 * SETTINGS
 */

// GRID
$columns: 12;
$gutter: 16/16*1rem;

$breakpoints: (
    xs: 480/16*1rem,
    sm: 640/16*1rem,
    md: 960/16*1rem,
    lg: 1024/16*1rem,
    xl: 1140/16*1rem,
);

// SPACING
$spacing: (
    auto: auto,
    none: 0/16*1rem,
    xs: 8/16*1rem,
    sm: 16/16*1rem,
    md: 32/16*1rem,
    lg: 40/16*1rem,
    xl: 64/16*1rem
);

// COLORS
$dark: #000000;
$medium: #5a5a5a;
$grey: #BFBFBF;
$light: #eeeeee;
$white: #ffffff;

$green: rgba(110,183,74,1);
$blue: rgba(4,79,154,1);
$red: rgba(232,71,41,1);
$yellow: rgba(240,201,40,1);
$purple: rgba(154,139,193,1);
$orange: rgba(248,170,35,1);

$primary: $orange;
$secondary: $red;
$tertiary: $blue;

$info: $purple;
$success: $green;
$warning: $orange;
$error: $red;

// SOCIAL MEDIA
$linkedin: #0077b5;
$facebook: #3b5998;
$twitter: #1da1f2;
$instagram: #c13584;

// BORDER RADIUS
$border-radius: 4px;

// TYPOGRAPHY
@import url('https://fonts.googleapis.com/css?family=Dosis:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:900');

$baseFont: 'Dosis', Helvetica, sans-serif;
$serifFont: 'Playfair Display', serif;

$min: 0.85;

$base-font-family: $baseFont;
$base-font-size-min: 1.15rem * $min;
$base-font-size-max: 1.15rem;
$base-font-weight: normal;
$base-line-height: 1.5;
$base-color: $dark;

$h1-font-family: $baseFont;
$h1-font-size-min: 2.5rem * $min;
$h1-font-size-max: 3.5rem;
$h1-font-weight: 700;
$h1-line-height: 1.35;
$h1-color: $dark;

$h2-font-family: $serifFont;
$h2-font-size-min: 1.85rem;
$h2-font-size-max: 2.5rem;
$h2-font-weight: 700;
$h2-line-height: 1.35;
$h2-color: $dark;

$h3-font-family: $baseFont;
$h3-font-size-min: 1.25rem;
$h3-font-size-max: 2.25rem;
$h3-font-weight: 700;
$h3-line-height: 1.35;
$h3-color: $dark;

$h4-font-family: $baseFont;
$h4-font-size-min: 1.15rem;
$h4-font-size-max: 1.85rem;
$h4-font-weight: 500;
$h4-line-height: 1.5;
$h4-color: $dark;

$h5-font-family: $baseFont;
$h5-font-size-min: 1rem;
$h5-font-size-max: 1.35rem;
$h5-font-weight: 400;
$h5-line-height: 1.5;
$h5-color: $dark;

$h6-font-family: $baseFont;
$h6-font-size-min: .95rem;
$h6-font-size-max: 1.15rem;
$h6-font-weight: 400;
$h6-line-height: 1.5;
$h6-color: $dark;
