/*
 * PARAGRAPHS
 */

p {
    margin-bottom: 1em;

    a {
        text-decoration: underline;
        transition: color 333ms ease;
        cursor: pointer;

        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }

    small {

        a {
            font-size: 1em;
        }
    }
}
