/*
 * IMAGE BG
 */

.u-img-bg {

    position: absolute;
    width: 100%;
    height: 100%;
    display: block;

    img {
        opacity: .125;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}