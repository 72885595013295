/*
 * CONTAINER
 */

.o-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 $gutter;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            max-width: map-get($breakpoints, $bp);
        }
    }
}

.o-half-container-md {

    @include media-query(md) {

        width: 100%;
        padding: 0 $gutter;
        margin: 0 !important;

        @each $bp in map-keys($breakpoints) {
            @include media-query($bp) {
                max-width: map-get($breakpoints, $bp) / 2 !important;
            }
        }
    }
}
