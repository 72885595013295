/*
 * SLICK SLIDER
 */


.c-slider-slick {

    visibility: hidden;
    opacity: 0;
}


.c-slider-slick.slick-initialized {

    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s, visibility 0.5s;
}


.c-slider-slick-nav {

    img {
        opacity: 0;
    }

    .slick-slide {

        padding: 8px 8px 0;
        cursor: pointer;

        img {
            opacity: 1;
        }
    }
}