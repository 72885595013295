/*
 * TEXT
 */

.u-text-container {
    > *:last-child {
        margin-bottom: 0;
    }
}

.u-text-2-columns {

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                column-count: 2;
                column-gap: 32px;
            }
        }
    }
}

.u-text-3-columns {

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                column-count: 3;
                column-gap: 32px;
            }
        }
    }
}

.u-text-4-columns {

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                column-count: 4;
                column-gap: 40px;
            }
        }
    }
}

.u-text-left {
    text-align: left;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                text-align: left;
            }
        }
    }
}

.u-text-right {
    text-align: right;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                text-align: right;
            }
        }
    }
}

.u-text-center {
    text-align: center;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                text-align: center;
            }
        }
    }
}