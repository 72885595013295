/*
 * ICONS
 */

.c-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    padding: 2px;
    fill: $dark;
}