/*
 * BUTTONS
 */

.c-button {
    display: inline-block;
    padding: .325em .625em;
    background-color: transparent;
    border-radius: $border-radius;
    border: 1px solid $dark;
    transition: all .333s ease;
    font-weight: normal;
    color: $dark;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    height: inherit;

    &:hover {
        background-color: $dark;
        text-decoration: none;
        color: white;
    }
}

.c-button.is-active {
    background-color: $dark;
    text-decoration: none;
    color: $white;
}

.c-button-white {
    border: 1px solid $white;
    color: $white;
    transition: all .333ms ease;

    &:hover {
        background-color: $white;
        color: $dark;
    }
}

.c-button-info {
    background-color: $info;
    color: $white;

    &:hover {
        background-color: lighten($info, 5%);
        color: $white;
    }
}

.c-button-success {
    background-color: $success;
    color: #fff;

    &:hover {
        background-color: lighten($success, 5%);
        color: $white;
    }
}

.c-button-warning {
    background-color: $warning;
    color: $white;

    &:hover {
        background-color: lighten($warning, 5%);
        color: $white;
    }
}

.c-button-error {
    background-color: $error;
    color: $white;

    &:hover {
        background-color: lighten($error, 5%);
        color: $white;
    }
}

.c-button-primary {
    background-color: $primary;
    border-color: $primary;
    color: $white;

    &:hover {
        background-color: lighten($primary, 5%);
        color: $white;
    }
}

.c-button-secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
        background-color: lighten($secondary, 5%);
        color: $white;
    }
}

.c-button-tertiary {
    background-color: $tertiary;
    color: $white;

    &:hover {
        background-color: lighten($tertiary, 5%);
        color: $white;
    }
}
