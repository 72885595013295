/*
 * MAIN
 */

#main {
    background-image: url('../images/patroon.png');
    background-repeat: repeat;
    background-size: 1900px 1600px;
}

#intro-image {
    position: relative;
    z-index: 1;
    background-color: $white;

    @include media-query('md') {
        background-color: transparent;
    }

    h1 {
        font-family: $baseFont;
        font-weight: normal;
        position: relative;

        span {
            display: inline-block;
            position: relative;
            z-index: 1;

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 16px;
                background-color: $red;
                position: absolute;
                bottom: 10px;
                z-index: -1;
            }
        }
    }
}

#intro-text {
    position: relative;
    z-index: 0;
    background-color: $white;

    @include media-query('md') {
        background-color: transparent;
    }

    p {
        margin: 0;
    }

    h2 {
        font-size: 1em;
        line-height: 1;

        span.normal {
            display: block;
            font-family: $baseFont;
            font-size: 1.25em;
            font-weight: normal;
            text-transform: none;
            margin-bottom: .33em;

            @include media-query('md') {
                font-size: 1.65em;
            }
        }

        span.bold {
            font-family: $serifFont;
            font-size: 2em;
            text-transform: uppercase;
            font-weight: 100;

            @include media-query('md') {
                font-size: 3em;
            }
        }
    }
}

#jenzen {

    .c-icon {
        display: block;
        color: $white;
        fill: $white;
        width: 75px;
        height: 75px;
        border: 1px solid $white;
        border-radius: 4px;
        cursor: pointer;
        transition: all .333s ease;
        margin: .5em;
    }

    .c-icon.jenzen-home {
        &:hover, &.isActive{
            color: $green;
            fill: $green;
            border-color: $green;
        }
    }

    .c-icon.jenzen-work {
        &:hover, &.isActive{
            color: $blue;
            fill: $blue;
            border-color: $blue;
        }
    }

    .c-icon.jenzen-kids {
        &:hover, &.isActive{
            color: $red;
            fill: $red;
            border-color: $red;
        }
    }

    .c-icon.jenzen-time {
        &:hover, &.isActive{
            color: $yellow;
            fill: $yellow;
            border-color: $yellow;
        }
    }

    .c-icon.jenzen-mind {
        &:hover, &.isActive{
            color: $purple;
            fill: $purple;
            border-color: $purple;
        }
    }

    .c-icon.jenzen-body {
        &:hover, &.isActive{
            color: $orange;
            fill: $orange;
            border-color: $orange;
        }
    }

    .c-icon-body {
        width: 75px;
        height: 75px;
        border-radius: $border-radius;
        margin: .5em;

        p {
            color: $dark;
            font-weight: bold;
            font-size: 1.25rem;
            text-transform: uppercase;
        }
    }

    .c-icon-body.jenzen-home-text {
        background-color: $green;
    }

    .c-icon-body.jenzen-work-text {
        background-color: $blue;
    }

    .c-icon-body.jenzen-kids-text {
        background-color: $red;
    }

    .c-icon-body.jenzen-time-text {
        background-color: $yellow;
    }

    .c-icon-body.jenzen-mind-text {
        background-color: $purple;
    }

    .c-icon-body.jenzen-body-text {
        background-color: $orange;
    }

    .c-slider-slide-0 {
        h3, h4, h5, h6 {
            color: $green;
        }
    }

    .c-slider-slide-1 {
        h3, h4, h5, h6 {
            color: $blue;
        }
    }

    .c-slider-slide-2 {
        h3, h4, h5, h6 {
            color: $red;
        }
    }

    .c-slider-slide-3 {
        h3, h4, h5, h6 {
            color: $yellow;
        }
    }

    .c-slider-slide-4 {
        h3, h4, h5, h6 {
            color: $purple;
        }
    }

    .c-slider-slide-5 {
        h3, h4, h5, h6 {
            color: $orange;
        }
    }

    img {
        border-radius: $border-radius;
    }
}

#testimonials {
    position: relative;

    .line {
        display: none;
        position: absolute;
        left: 50%;
        margin-left: -8px;
        top: 50px;

        @include media-query('sm') {
            top: 50px;

            &:before {
                height: 50px;
                top: -50px;
            }
        }

        @include media-query('md') {
            top: 50px;

            &:before {
                height: 50px;
                top: -50px;
            }
        }

        @include media-query('lg') {
            top: 100px;

            &:before {
                height: 100px;
                top: -100px;
            }
        }
    }

    .o-container {
        position: absolute;

        .testimonial-text p {
            font-size: 1.15rem;
        }

        p {
            font-size: .85em;
        }

        @include media-query('md') {

            .testimonial-text p {
                font-size: 1.85rem;
            }

            p {
                font-size: 1.25em;
            }
        }
    }
}

#about {

    h2 {
        text-transform: uppercase;
    }

    h3 {
        text-transform: uppercase;

        span {
            font-weight: 100;
        }
    }
}

#keep-in-touch {

    h2 {
        font-weight: 100;
        color: $white;
        text-transform: uppercase;
    }
}

#featured,
.c-event-header,
.c-blog-header {
    position: relative;

    .c-featured-text,
    .c-event-header-text,
    .c-blog-header-text {
        position: absolute;

        h1, h3 {
            font-family: $baseFont;
            background-color: $dark;
            color: $white;
            font-weight: 500;
            text-transform: uppercase;
        }

        h1 {
            font-size: 1.75rem;
        }

        h3 {
            font-size: 1.25rem;
        }

        @include media-query(md) {

            h1 {
                font-size: 3.5rem;
            }

            h3 {
                font-size: 2.25rem;
            }
        }
    }
}

.c-event-image,
.c-event-info {
    border-radius: 16px;
    box-shadow: 1px 1px 2px 1px #e4e4e4;
}

.c-event-info {
    background-color: $white;
    text-decoration: none;

    a, h3 {
        text-decoration: none !important;
    }

    h3 span {
        font-size: 1.25rem;
    }

    .c-icon {
        width: 28px;
        height: 28px;
        border-radius: $border-radius;
        background-color: $dark;
        fill: $white;

        @include media-query(md) {
            width: 36px;
            height: 36px;
        }
    }
}

#contact {

    h3, h4 {
        color: $white;
    }

    h3 {
        text-transform: uppercase;
    }

    h4 {
        font-weight: bold;
    }

    .c-contact-info {
        border: 1px solid $white;
        border-radius: 16px;
    }
}

// General items

.line {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: $border-radius;
    background-color: $dark;

    &:before {
        z-index: 0;
        content: '';
        display: block;
        width: 1px;
        height: 150px;
        left: 50%;
        background-color: $dark;
        position: absolute;
        top: -150px;
    }
}

.c-divider {

    width: 35%;
    background-color: $dark;
    height: 1px;
    display: block;
    position: relative;

    &:before,
    &:after {

        content: '';
        width: 16px;
        height: 16px;
        top: -8px;
        background-color: $dark;
        border-radius: $border-radius;
        display: block;
        position: absolute;

    }

    &:before {

        left: 0;
    }

    &:after {

        right: 0;
    }
}

.c-section.c-section-dark {
    background-color: $dark;

    h2, h3, h4, h5, h6 {
        color: $orange;
    }

    p {
        color: $white;
    }
}

button.c-button-submit {
    background-color: transparent;
    border: 1px solid $white;
    width: 100%;
    border-radius: $border-radius;
    transition: background-color .333s ease;

    .c-icon {
        width: 80px;
        height: 35px;
        fill: $white;
        transition: fill .333s ease;
    }

    &:hover {
        background-color: $white;

        .c-icon {
            fill: $dark;
        }
    }
}

.c-social-title {

    h6, p {
        text-align: center;
    }

    h6 {
        color: $white !important;
        font-family: $serifFont;
        text-transform: uppercase;
    }

    p {
        color: $red !important;
    }
}

.c-social-btn {
    background-color: $dark;
    border-radius: 4px;
    border: 1px solid $white;
    font-size: 1em !important;
    line-height: 1 !important;
    transition: background-color .333s ease;

    .c-icon {
        fill: $white;
        transition: fill .333s ease;
    }

    &:hover {
        background-color: $white;

        .c-icon {
            fill: $dark;
        }
    }
}

.c-cat-menu {

    a.c-cat-menu-item {

        &:hover {
            border-color: $white;
            background-color: $primary;
            color: $white;
        }
    }
}

.vue-map-container {
    width: 100%;
    height: 500px;
}
