/*
 * LISTS
 */

ul,
ol {
    margin-bottom: 1em;
}

li {

    a {
        color: $primary;

        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }

    ul,
    ol {
        margin-top: 0.5em;
        margin-left: 1em;
    }

    ol {
        list-style-type: lower-latin;
    }
}