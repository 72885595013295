/*
 * FORMS
 */

input,
textarea,
select,
button {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    border: 0;
    appearance: none;
}