/*
 * FUNCTIONS
 */

// TO REM
@function rem($size) {
    @return $size/16*1rem;
}

// FLUID FONT-SIZE
@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
    $a: ($max-value - $min-value) / ($max-screen - $min-screen);
    $b: $min-value - $a * $min-screen;

    $sign: "+";

    @if ($b < 0) {
        $sign: "-";
        $b: abs($b);
    }

    @return calc(#{$a*100}vw #{$sign} #{$b});
}